<template>
  <el-dialog class="dialog-add-person-collect" :title="`${preTitle}教学信息采集`" :visible.sync="show"
             :close-on-click-modal="false" width="1000px" :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="170px">

      <el-divider content-position="left">
        <tool-tips-group
          tips-content="若切换模块出现问题，请检查基本信息采集中的职工类别是否为辅导员、教师、行政人员、中层干部">
          <h3 v-if="!isOnlyRead">{{ this.teacherType }}-教学信息采集:</h3>
          <h3 v-else>教学信息采集详情</h3>
        </tool-tips-group>
      </el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="模块" prop="gradeName">
            <el-select v-model="nowPersonCollect" placeholder="请选择">
              <el-option v-for="(item, index) in nowPersonCollectList" :key="index" :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="学年" prop="gradeName">
            <el-input v-if="id && formData.gradeName" disabled v-model="formData.gradeName"/>
            <el-select v-else v-model="formData.gradeName" placeholder="请选择">
              <el-option v-for="item in gradeList" :key="item.id" :label="item.gradeName"
                         :value="item.gradeName"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="学期" prop="term">
            <el-input v-if="id && formData.term" disabled v-model="formData.term"/>
            <el-select v-else v-model="formData.term">
              <el-option v-for="item in termList" :key="item.id" :label="`第${item.paramValue}学期`"
                         :value="item.paramValue"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <form-term-info v-if="nowPersonCollect === '本学期汇报'" :isOnlyRead="isOnlyRead" :parentId="id"
                      :term="formData.term" :gradeName="formData.gradeName" />
      <form-thesis v-if="nowPersonCollect === '论文'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                   :term="formData.term" :gradeName="formData.gradeName" />
      <form-patent v-if="nowPersonCollect === '专利'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                   :term="formData.term" :gradeName="formData.gradeName" />
      <form-subject v-if="nowPersonCollect === '课题'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                    :term="formData.term" :gradeName="formData.gradeName" />
      <form-competition v-if="nowPersonCollect === '竞赛'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                        :term="formData.term" :gradeName="formData.gradeName" />
      <form-course-record v-if="nowPersonCollect === '教育教学'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                          :term="formData.term" :gradeName="formData.gradeName" />
      <form-article v-if="nowPersonCollect === '稿件'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                    :term="formData.term" :gradeName="formData.gradeName" />
      <form-work-result v-if="nowPersonCollect === '著作'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                        :term="formData.term" :gradeName="formData.gradeName" />
      <form-social-practive v-if="nowPersonCollect === '社会实践'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                            :term="formData.term" :gradeName="formData.gradeName" />
      <form-social-serve v-if="nowPersonCollect === '社会服务'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                         :term="formData.term" :gradeName="formData.gradeName" />
      <form-graduate-internship v-if="nowPersonCollect === '毕业生顶岗实习'" :CurUserId="formData.userId"
                                :isOnlyRead="isOnlyRead" :term="formData.term" :gradeName="formData.gradeName" />
      <form-student-activity v-if="nowPersonCollect === '学生活动'" :CurUserId="formData.userId"
                             :isOnlyRead="isOnlyRead"
                             :term="formData.term" :gradeName="formData.gradeName" />
      <form-class-collect v-if="nowPersonCollect === '班级汇总'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                          :term="formData.term" :gradeName="formData.gradeName" />
      <form-class-arrear v-if="nowPersonCollect === '欠费汇总'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                         :term="formData.term" :gradeName="formData.gradeName" />
      <form-go-out-work v-if="nowPersonCollect === '外出公干'" :CurUserId="formData.userId" :isOnlyRead="isOnlyRead"
                        :term="formData.term" :gradeName="formData.gradeName" />
    </el-form>
  </el-dialog>
</template>

<script>
import { personCollectList } from './options'

import { getGradeList } from '@/api/grade.js'
import { getParamsList } from '@/api/sysParams.js'
import { getInfoCollectByUserId } from '@/api/infoCollect/infoCollect-api.js'
import { getPersonCollectByIdApi } from '@/api/infoCollect/personCollect-api.js'

import dialog from '@/vue/mixins/dialog'

import FormTermInfo from './formTermInfo.vue'
import FormThesis from './formThesis.vue'
import FormPatent from './formPatent.vue'
import FormSubject from './formSubject.vue'
import FormCompetition from './formCompetition.vue'
import FormCourseRecord from './formCourseRecord.vue'
import FormArticle from './formArticle.vue'
import FormWorkResult from './formWorkResult.vue'
import FormSocialPractive from './formSocialPractive.vue'
import FormSocialServe from './formSocialServe.vue'
import FormGraduateInternship from './formGraduateInternship.vue'
import FormStudentActivity from './formStudentActivity.vue'
import FormClassCollect from './formClassCollect.vue'
import FormClassArrear from './formClassArrear.vue'
import FormGoOutWork from './formGoOutWork.vue'

export default {
  name: 'DialogAddPersonCollect',
  mixins: [dialog],
  components: {
    FormTermInfo,
    FormThesis,
    FormPatent,
    FormSubject,
    FormCompetition,
    FormCourseRecord,
    FormArticle,
    FormWorkResult,
    FormSocialPractive,
    FormSocialServe,
    FormGraduateInternship,
    FormStudentActivity,
    FormClassCollect,
    FormClassArrear,
    FormGoOutWork
  },
  props: {
    isOnlyRead: Boolean,
    isOnlyReadteacherType: String
  },
  data() {
    return {
      personCollectList,
      nowPersonCollectList: '',
      nowPersonCollect: '',
      teacherType: '',
      formData: {
        id: '',
        gradeName: '',
        term: '',
        workResult: '',
        selfOpinion: '',
        improveWayResult: '',
        stuEmergencyRecord: '',
        awards: '',
        nextTermPlan: '',
        userId: ''
      },
      gradeList: {},
      termList: {},
      rules: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initDate()
      this.getGradeList()
      this.getTermList()
      this.getNowTeacherType()
      if (this.id) {
        this.getDataById(this.id, getPersonCollectByIdApi)
      }

      this.nowPersonCollect = '本学期汇报'
      if (this.isOnlyRead === true) {
        this.preTitle = '查看'
      }
    })
  },
  methods: {
    initDate() {
      const time = new Date()
      const year = time.getFullYear()
      const month = time.getMonth() + 1

      // 当年3月-当年8月为第二学期，当年9月-次年2月为第一学期
      if (month >= 3 && month < 9) {
        this.formData.term = '2'
        this.formData.gradeName = `${year - 1}-${year}`
      } else if (month >= 9) {
        this.formData.term = '1'
        this.formData.gradeName = `${year}-${year + 1}`
      } else {
        this.formData.term = '1'
        this.formData.gradeName = `${year - 1}-${year}`
      }
    },
    // 获取学年
    getGradeList() {
      getGradeList({}).then((res) => {
        this.gradeList = res.data.list
      })
    },
    //  获取学期
    getTermList() {
      getParamsList({ keyword: 'term' }).then((res) => {
        this.termList = res.data.list
      })
    },
    // 获取当前教师身份
    async getNowTeacherType() {
      if (this.isOnlyRead === true) {
        this.teacherType = this.isOnlyReadteacherType
      } else {
        const userId = this.$store.state.login.userInfo.id
        await getInfoCollectByUserId(userId).then((res) => {
          this.teacherType = res.data.teacherType
        })
      }

      personCollectList.map((item) => {
        if (item.type === this.teacherType) {
          this.nowPersonCollectList = item.infoCollectOption
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-add-person-collect {
  .el-dialog {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    min-height: calc(100vh - 94px);

  }
}

.collect-form {

  .el-textarea,
  .el-input,
  .el-select {
    &.is-disabled {

      .el-textarea__inner,
      .el-input__inner {

        background-color: #fff !important;
        color: #606266 !important;
      }
    }
  }
}
</style>
