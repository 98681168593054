<template>
  <div class="upload-single-img">
    <template v-if="!url">
      <el-upload class="upload-demo" :action="action" :headers="headers" :on-success="onSuccess"
        :before-upload="beforeUpload" :on-remove="remove">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传zip/rar文件，且不超过20M</div>
      </el-upload>
    </template>
    <div class="image-preview-box" v-if="url">
      <div class="del-shadow" @click="remove">
        <i class="el-icon-delete"></i>
      </div>
      <div slot="tip" class="flex">{{ url }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'singleImgUploader',
  props: {
    url: String,
    width: {
      type: String,
      default: '120px'
    },
    height: {
      type: String,
      default: '120px'
    },
    imgFit: {
      type: String,
      default: 'fill',
      validator: size => ['fill', 'contain', 'cover', 'none', 'scale-down'].includes(size)
    }
  },
  data() {
    return {
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      action: `${process.env.VUE_APP_API_URL}/auth/upload/zipFile`,
      disabled: false
    }
  },
  methods: {
    // 删除图片
    remove() {
      this.$emit('update:url', '')
      if (this.$refs.uploaderRef) {
        this.$refs.uploaderRef.clearFiles()
      }
      this.disabled = false
    },
    // 上传前
    beforeUpload(file) {
      this.disabled = true
      const imgTypes = ['zip', 'rar', 'ZIP', 'RAR']
      const fileType = file.name.split('.').slice(-1)[0]

      const isImg = imgTypes.includes(fileType)
      const isLt20M = file.size / 1024 / 1024 < 20

      if (!isImg) {
        this.$message.error('仅支持zip/rar文件')
        return false
      }
      if (!isLt20M) {
        this.$message.error('上传图片不能超过 3MB!')
        return false
      }
      return isImg && isLt20M
    },
    // 上传成功
    onSuccess(res) {
      if (res.success) {
        this.$emit('update:url', res.data)
        this.$emit('on-success')
      } else {
        this.$message.error('上传失败!')
      }
    }
  }
}
</script>

<style lang="scss">
.flex {
  display: flex;
}

.upload-single-img {
  .avatar-uploader {
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    color: $color-primary;
    font-size: 2rem;

    .el-upload {
      width: 100%;
      height: 100%;
      cursor: pointer;
      vertical-align: top;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      border-color: $color-primary;
    }
  }

  .image-preview-box {
    position: relative;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;

    .el-image-preview {
      height: auto;
    }

    .del-shadow {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
      z-index: 9;
      background: rgba(0, 0, 0, .4);
      cursor: pointer;
      display: none;
      border-radius: 4px;

      .el-icon-delete {
        display: inline-block;
        height: auto;
        font-size: 16px;
      }
    }

    &:hover {
      .del-shadow {
        display: flex;

        &:hover {
          color: $color-danger;
        }
      }
    }

  }
}
</style>
